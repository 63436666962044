<template>
  <div>
    <el-breadcrumb style="margin: 0 0 20px 10px" separator="/">
      <el-breadcrumb-item>扫码信息</el-breadcrumb-item>
      <el-breadcrumb-item>扫码列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">危废管理</div>
      <div class="xian"></div> -->
      <div class="list_box">
        <div class="tt1">扫码列表</div>
        <div>
          <!-- <el-button type="add" icon="el-icon-circle-plus-outline" @click="add"
            >添加</el-button
          > -->
        </div>
      </div>
      <div class="int_box">
        <div class="int_box11">
          <span>流水号：</span>
          <el-input
            class="int_width"
            v-model="no"
            placeholder="请输入流水号"
            clearable
          ></el-input>
          <el-button
            size="small"
            style="margin-left: 50px"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="userList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            width="60"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column prop="no" label="流水号"></el-table-column>
          <el-table-column min-width="220" show-overflow-tooltip prop="address" label="扫码地址"></el-table-column>
          
          <!-- <el-table-column
            align="center"
            prop="scan_num"
            label="扫码次数"
          ></el-table-column> -->
          <el-table-column
            prop="product_name"
            label="对应产品"
          ></el-table-column>
          <el-table-column
            width="170px"
            prop="create_at"
            label="扫码时间"
          ></el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 20]"
          :page-size="pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[3456789]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      page: 1,
      pageNumber: 10,
      no: '',
      userList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },
    }
  },
  created() {
    this.user()
  },
  methods: {
    handleSizeChange(val) {
      this.pageNumber = val
      this.user()
    },
    handleCurrentChange(val) {
      this.page = val
      this.user()
    },
    // 列表
    async user() {
      const { data: res } = await this.$http.get('api/qrcode/getscanlist', {
        params: {
          page: this.page,
          size: this.pageNumber,
          no: this.no,
        },
      })
      this.total = res.data.count
      this.userList = res.data.list

    },
    // 查询
    chaxun() {
      this.page = 1
      this.user()
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box {
    padding: 20px 1px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .int_box11 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .int_width {
    width: 240px;
    margin-right: 40px;
  }
  .int_width1 {
    width: 300px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .int {
    width: 400px;
  }

  .add_btn {
    margin-left: 800px;
  }
  .danger_btn {
    margin-left: 20px;
  }
  .btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .add_btn111 {
    width: 140px;
  }
}
</style>
